import Dayjs, { Dayjs as DayjsType } from 'dayjs';
import { DateFormat } from 'components';
import { dayjsWithTimezone } from '../utils';

export const DateRangeFormat = {
	full: 'DD.[&nbsp;]MMM[&nbsp;]YYYY',
	monthNumber: 'DD.[&nbsp;]MM.[&nbsp;]YYYY',
	weekday: 'dddd, DD. MMMM YYYY, ',
	timeDuration: 'dddd, DD. MMMM YYYY - ',
	date: 'dddd, DD. MMMM YYYY',

	// same day: DD.MM.YYYY
	// different days: DD.MM – DD.MM.YYYY
	constantDate: 'DD.MM. – DD.MM.YYYY'
};

export type DateRangeFormat = typeof DateRangeFormat[keyof typeof DateRangeFormat];

export const datesDelimiter = '—';

// Date format docs: https://fomfsite.atlassian.net/wiki/spaces/NR/pages/2739732481/2022-01-25+date+format
const formatDateRange = (
	_startDate: DayjsType,
	_endDate: DayjsType,
	outputFormat?: DateRangeFormat | null,
	locale?: string
): string => {
	const startDate = dayjsWithTimezone(_startDate);
	const endDate = dayjsWithTimezone(_endDate);
	const datesSameYear = startDate.isSame(endDate, 'year');
	const datesSameMonth = datesSameYear && startDate.isSame(endDate, 'month');
	const datesSameDay = datesSameMonth && startDate.isSame(endDate, 'day');

	const dayMonthFormat =
		outputFormat === DateRangeFormat.monthNumber ? 'DD.[&nbsp;]MM.' : 'DD.[&nbsp;]MMM';
	const weekdayMonthYearFormat = outputFormat ?? DateRangeFormat.full;
	const dayMonthYearFormat = 'DD.[&nbsp;]MMM[&nbsp;]YYYY';

	if (datesSameDay) {
		const dayDate = Dayjs(startDate)
			.locale(locale ?? 'de-DE')
			.format(weekdayMonthYearFormat);
		let time = '';
		switch (outputFormat) {
			case DateRangeFormat.timeDuration: {
				time = Dayjs.duration(Math.abs(startDate.diff(endDate)))
					.locale(locale ?? 'de-DE')
					.humanize();
				break;
			}
			case DateRangeFormat.constantDate:
				return startDate.format(DateFormat.date);
			case DateRangeFormat.date:
				break;
			default: {
				const startTime = startDate.format(DateFormat.time);
				const endTime = endDate.format(DateFormat.time);
				time = startTime !== endTime ? ` ${startTime} - ${endTime}` : '';
			}
		}
		return `${dayDate}${time}`;
	}
	if (outputFormat === DateRangeFormat.constantDate) {
		return `${startDate.format('DD.MM.')} – ${endDate.format('DD.MM.YYYY')}`;
	}

	const dateLabel =
		(datesSameDay && startDate.format(weekdayMonthYearFormat)) ||
		(datesSameMonth &&
			`${startDate.format(dayMonthFormat)}&nbsp;${datesDelimiter}&nbsp;${endDate.format(
				dayMonthYearFormat
			)}`) ||
		(datesSameYear &&
			`${startDate.format(dayMonthFormat)} ${datesDelimiter} ${endDate.format(
				weekdayMonthYearFormat
			)}`) ||
		`${startDate.format(weekdayMonthYearFormat)} ${datesDelimiter} ${endDate.format(
			weekdayMonthYearFormat
		)}`;

	return dateLabel;
};

export default formatDateRange;
